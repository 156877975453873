import React, { useEffect, useState } from "react";
// import { graphql } from "gatsby";
// import Seo from "../components/common/Seo";
import HowItWorkBanner from "../components/howItWorks/HowItWorkBanner";
import CountryDestination from "../components/howItWorks/CountryDestination";

//
const AboutUs = () => {
  return (
    <>
      <HowItWorkBanner />
      <CountryDestination />
    </>
  );
};

export default AboutUs;
