import React, { useEffect, useState } from "react";
import AboutUsBanner from "../components/aboutUs/AboutUsBanner";
//
const AboutUs = () => {
  return (
    <>
      <AboutUsBanner />
    </>
  );
};

export default AboutUs;
