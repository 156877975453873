import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
// import { Link } from "gatsby";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";

const WhyChooseUs = () => {
  const destination = [
    {
      label: "Reliable, On-Time Delivery",
      dec: "We ensure every shipment reaches its destination as scheduled, thanks to our meticulous planning and execution.",
      // img: "/images/logo.svg",
    },
    {
      label: "Comprehensive Global Network",
      dec: "Our vast network of partners and hubs around the world allows us to serve a wide range of locations quickly and efficiently.",
      // img: "/images/logo.svg",
    },
    {
      label: "Personalized Solutions",
      dec: "We customize logistics solutions based on each client's specific needs,providing flexible options for every shipment.",
      // img: "/images/logo.svg",
    },
    {
      label: "Transparent Communication",
      dec: "Stay informed with real-time updates and clear communication, giving you peace of mind at every step of the journey.",
      // img: "/images/logo.svg",
    },
    {
      label: "Competitive Pricing",
      dec: "Our optimized operations allow us to offer highly competitive rates without compromising on quality or speed.",
      // img: "/images/logo.svg",
    },
    {
      label: "Experienced Logistics Team",
      dec: "Our team of experts brings years of industry knowledge to handle complex shipments and troubleshoot challenges swiftly.",
      // img: "/images/logo.svg",
    },
    {
      label: "Commitment to Sustainability",
      dec: "We adopt eco-friendly practices to reduce our environmental impact, ensuring a sustainable future for global logistics.",
      // img: "/images/logo.svg",
    },
    {
      label: "End-to-End Support",
      dec: "From pickup to customs clearance and delivery, we provide full support, making the shipping process smooth and hassle-free.",
      // img: "/images/logo.svg",
    },
    {
      label: "Advanced Technology Integration",
      dec: "We use the latest tracking systems and logistics technology, ensuring accuracy and security for all shipments.",
      // img: "/images/logo.svg",
    },
    {
      label: "Exceptional Customer Service",
      dec: "Our customer service team is dedicated to addressing your needs, available to assist and answer questions at any time.",
      // img: "/images/logo.svg",
    },
  ];

  return (
    <section className={`${styles.WhyChooseUs}`}>
      <Container className={`${styles.container}`}>
        <h1>Why Choose Us? </h1>
        <Row className={`${styles.row}`}>
          <Col sx={12} md={6} lg={6} className={`${styles.colLeft}`}>
            <>
              {destination.map((section, index) => (
                <div key={index}>
                  {/* <img className={`${styles.img}`} src={link.img} alt="img" /> */}

                  <h5 className={`${styles.colLeftP}`}> {section.label}</h5>
                  <p>{section.dec}</p>
                </div>
              ))}
            </>
          </Col>
          <Col sx={12} md={6} lg={6} className={`${styles.colRight}`}>
            <img
              className={`${styles.img}`}
              src="/images/choose.jpeg"
              alt="img"
            />
          </Col>
        </Row>
        <p className={`${styles.para}`}>
          Choosing Smart Shipping Services means partnering with a logistics
          provider dedicated to more than just transporting goods. We bring a
          personalized, flexible approach to every shipment, backed by a team of
          logistics experts who manage each detail with precision and care. Our
          robust global network allows us to offer competitive rates and quick
          turnaround times, while our transparent communication keeps clients
          informed at every stage. Whether you need international freight,
          reliable customs support, or tailored logistics solutions, Smart
          Shipping Services provides a trusted, efficient, and smart choice for
          all your shipping needs.
        </p>
      </Container>
    </section>
  );
};

export default WhyChooseUs;
