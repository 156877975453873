import React, { useEffect, useState } from "react";
// import { graphql } from "gatsby";
// import Seo from "../components/common/Seo";
import ContactUsBanner from "../components/contactUs/ContactUsBanner";
//
const AboutUs = () => {
  return (
    <>
      <ContactUsBanner />
    </>
  );
};

export default AboutUs;
