import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";

const HomeBannerNew = () => {
  const HomeBannerNew = [
    {
      title:
        "Beyond Borders, Beyond Limits – Shipping Solutions with Smart Precision.",
      subtitle:
        "Efficient, Reliable, and Hassle-Free Shipping to Any Destination",
      btn: "Get Started",
    },
  ];
  return (
    <section className={` ${styles.homeBannerNew}`}>
      <Row className={`align-items-stretch ${styles.row}`}>
        {HomeBannerNew.map((section, index) => (
          <Col sx={12} md={6} className={`${styles.colLeft}`} key={index}>
            <h1>Smart Shipping</h1>
            <h2>{section.title}</h2>
            {/* <p>{section.subtitle}</p> */}
            {/* <Link to="/shippment/"> */}
            <Button
              type="submit"
              variant="none"
              className={`secondary mt-4 ${styles.button}`}
            >
              {section.btn}
            </Button>
            {/* </Link> */}
          </Col>
        ))}

        <Col sx={12} md={6} className={`${styles.colRight}`}>
          <video
            src="/images/videos/home.mp4"
            autoPlay
            loop
            muted
            playsInline
            className={`${styles.video}`}
          ></video>
        </Col>
      </Row>
    </section>
  );
};

export default HomeBannerNew;
