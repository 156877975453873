import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";

const OurMission = () => {
  const OurMission = [
    {
      title: "Mission Statement",
      subtitle:
        "Smart Shipping Services is committed to revolutionizing global logistics by providing reliable, efficient, and secure shipping solutions tailored to meet the diverse needs of our clients. We strive to bridge global connections with precision and excellence, ensuring that each shipment, whether by air, sea, or land, reaches its destination on time and intact. ",
      img: "/images/mission.jpeg",
    },
  ];
  const OurVision = [
    {
      title: "Our Goal",
      subtitle:
        "Our goal is to redefine customer satisfaction through transparent communication, responsive service, and a seamless experience, every time.",
      img: "/images/packing.jpg",
    },
  ];
  const OurGoal = [
   
    {
      title: "Vision Statement",
      subtitle:
        "To be a leader in the global shipping industry, recognized for our unwavering dedication to innovation, customer-centric solutions, and environmentally responsible practices. We envision a future where Smart Shipping Services sets the standard for efficiency and reliability, empowering businesses and individuals to achieve their global trade ambitions with confidence and ease.",
      img: "/images/vision.jpg",
    },
  ];

  return (
    <section className={` ${styles.OurMission}`}>
      <Container>
        {OurMission.map((section, index) => (
          <Row className={`${styles.row}`}>
            <Col sx={12} md={6} className={`${styles.colLeft}`} key={index}>
              <img src={section.img} alt="immg" />
            </Col>

            <Col sx={12} md={6} className={`${styles.colRight}`}>
              <div>
                <h4>{section.title}</h4>
                <p>{section.subtitle}</p>
              </div>
            </Col>
          </Row>
        ))}
        {OurVision.map((section, ind) => (
          <Row className={`${styles.row} ${styles.row2}`}>
            <Col sx={12} md={6} key={ind} className={`${styles.colRight}`}>
              <div>
                <h4>{section.title}</h4>
                <p>{section.subtitle}</p>
              </div>
            </Col>

            <Col sx={12} md={6} className={`${styles.colLeft}`}>
              <img src={section.img} alt="immg" />
            </Col>
          </Row>
        ))}

        {OurGoal.map((section, index) => (
          <Row className={`${styles.row}`}>
            <Col sx={12} md={6} className={`${styles.colLeft}`} key={index}>
              <img src={section.img} alt="immg" />
            </Col>

            <Col sx={12} md={6} className={`${styles.colRight}`}>
              <div>
                <h4>{section.title}</h4>
                <p>{section.subtitle}</p>
              </div>
            </Col>
          </Row>
        ))}
      </Container>
    </section>
  );
};

export default OurMission;
