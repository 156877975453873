import React, { useEffect, useState } from "react";
// import { graphql } from "gatsby";
// import Seo from "../components/common/Seo";
import ShippingBanner from "../components/shippingCalculator/ShippingBanner";
//
const shippingCalculator = ({ data }) => {
  return (
    <>
      <ShippingBanner />
    </>
  );
};

export default shippingCalculator;
