import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./components/layout/index";
import HomePage from "./pages/index";
import AboutUs from "./pages/about-us";
import Services from "./pages/services";
import HowItWorks from "./pages/how-it-works";
import ShippingCalculator from "./pages/shipping-calculator";
import ContactUs from "./pages/contact-us";
import Register from "./pages/register";
import Login from "./pages/login";
import LostPassword from "./components/register/LostPassword";
import Shippment from "./components/Shippment/ShippmentForm";
import ShippingForm from "./components/shippingForm/ShippmentFormDetails";
import RequestQuote from "./components/services/RequestAQuote";
import Tracking from "./components/services/Tracking";
import Schedules from "./components/services/Schedules";
import Booking from "./components/services/Booking";
import Form from "./components/services/Form";
// import  from "./components/services/";

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about-us/" element={<AboutUs />} />
          <Route path="/services/" element={<Services />} />
          <Route path="/destination/" element={<HowItWorks />} />
          <Route
            path="/shipping-calculator/"
            element={<ShippingCalculator />}
          />
          <Route path="/contact-us/" element={<ContactUs />} />
          <Route path="/register/" element={<Register />} />
          <Route path="/login/" element={<Login />} />
          <Route path="/lost-password/" element={<LostPassword />} />
          <Route path="/shippment/" element={<Shippment />} />
          <Route path="/shippment/" element={<Shippment />} />
          <Route path="/request-quote/" element={<RequestQuote />} />
          <Route path="/tracking/" element={<Tracking />} />
          <Route path="/schedules/" element={<Schedules />} />
          <Route path="/booking/" element={<Booking />} />
          <Route path="/form/" element={<Form />} />

          {/* <Route path="/shippingForm/" element={<ShippingForm />} /> */}
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
