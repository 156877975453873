import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
// import { Link } from "gatsby";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";

const HowItWorkBanner = () => {
  const destination = [
    {
      title: "Destinations We Cover",
      subtitle:
        "At Smart Shipping Services Inc., we specialize in shipping to a wide range of key destinations across West Africa and Central Africa. Our deep expertise in these regions ensures your goods are delivered efficiently, securely, and on time.",
      data: [
        {
          heading: "West Africa and Central Africa Destinations:",
          links: [
            { label: "Lagos, Nigeria", img: "/images/logo.svg" },
            { label: "Lome, Togo", img: "/images/logo.svg" },
            { label: "Cotonou, Benin", img: "/images/logo.svg" },
            { label: "Tema, Ghana", img: "/images/logo.svg" },
            { label: "Dakar, Senegal", img: "/images/logo.svg" },
            { label: "Abidjan, Ivory Coast", img: "/images/logo.svg" },
            {
              label: "Pointe Noire, Republic of the Congo",
              img: "/images/logo.svg",
            },
            { label: "Luanda, Angola", img: "/images/logo.svg" },
            { label: "Douala, Cameroon", img: "/images/logo.svg" },
            { label: "Libreville, Gabon", img: "/images/logo.svg" },
            { label: "Takoradi, Ghana", img: "/images/logo.svg" },
            { label: "Banjul, The Gambia", img: "/images/logo.svg" },
            { label: "Conakry, Guinea", img: "/images/logo.svg" },
            { label: "Malabo, Equatorial Guinea", img: "/images/logo.svg" },
            { label: "Freetown, Sierra Leone", img: "/images/logo.svg" },
          ],
          dec: "Whether you’re shipping vehicles, machinery, or personal goods, we ensure a smooth process from the port of origin to the final destination. Contact us today to get a tailored shipping solution for any of these locations!",
        },
      ],
    },
  ];

  return (
    <section className={`${styles.HowItWorkBanner}`}>
      <Container className={`${styles.HowItWorkBannercontainer}`}>
        <h1>Destinations We Cover</h1>
        <p className={`${styles.p}`}>
          At Smart Shipping Services Inc., we specialize in shipping to a wide
          range of key destinations across West Africa and Central Africa. Our
          deep expertise in these regions ensures your goods are delivered
          efficiently, securely, and on time.
        </p>

        <div className={`${styles.containerImgWrap}`}>
          <img
            src="/images/destination.jpeg"
            alt="immg"
            className={`${styles.containerImg}`}
          />
        </div>
        {/* <div class="bg-primary-50 h-[13rem]"></div> */}
      </Container>
      <div className={`${styles.container}`}>
        <Container>
          {destination.map((section, index) => (
            <Row className={`${styles.row}`} key={index}>
              <>
                {section.data.map((item, dataIndex) => (
                  <React.Fragment key={dataIndex}>
                    <>
                      <h4>{item.heading}</h4>
                      {item.links.map((link, linkIndex) => (
                        <Col
                          sx={6}
                          md={3}
                          lg={2}
                          className={`${styles.colLeft}`}
                        >
                          {/* <img
                            className={`${styles.img}`}
                            src={link.img}
                            alt="img"
                          /> */}
                          <p className={`${styles.colLeftP}`} key={linkIndex}>
                            {link.label}
                          </p>
                        </Col>
                      ))}
                    </>
                    <Col
                      sx={12}
                      md={12}
                      lg={12}
                      className={`${styles.colRight}`}
                    >
                      <p className={`${styles.colRightP}`}>{item.dec}</p>
                    </Col>
                  </React.Fragment>
                ))}
              </>
            </Row>
          ))}
        </Container>
      </div>
    </section>
  );
};

export default HowItWorkBanner;
