import React, { useEffect, useState } from "react";
import ServicesBanner from "../components/services/ServicesBanner";
//
const Services = () => {
  return (
    <>
      <ServicesBanner />
    </>
  );
};

export default Services;
