import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { RiInstagramLine } from "react-icons/ri";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";

const Footer = () => {
  const footerData = [
    {
      title: "Get Involved",
      items: [
        { label: "Home", url: "/" },
        { label: "Services", url: "/services/" },
        { label: "About Us", url: "/about-us/" },
        { label: "Contact Us", url: "/contact-us/" },
        // { label: "Privacy Policy", url: "/" },
        // { label: "Terms of Service", url: "/" },
      ],
    },
  ];
  const privacyData = [
    { label: "Privacy Policy", url: "/" },
    { label: "Terms of Service", url: "/" },
  ];

  const socialMediaLinks = [
    { icon: <FaFacebookF />, url: "https://facebook.com" },
    { icon: <RiInstagramLine />, url: "https://instagram.com" },
    { icon: <FaLinkedinIn />, url: "https://linkedin.com" },
    { icon: <FaTwitter />, url: "https://twitter.com" },
  ];
  return (
    <>
      <footer className={styles.footer}>
        <Container className={styles.Container}>
          <Row className="row">
            <Col sx={6} md={4} lg={4} className={`${styles.col}`}>
              <Link to="/">
                <img
                  src="/images/new.png"
                  className="d-inline-block"
                  alt="Logo"
                  width={150}
                  height={80}
                />
              </Link>

              {/* Social Media Icons */}
              <div className={styles.socialMedia}>
                {socialMediaLinks.map((social, index) => (
                  <div className={styles.socialIconWrap}>
                    <Link
                      key={index}
                      to={social.url}
                      className={styles.socialIcon}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {social.icon}
                    </Link>
                  </div>
                ))}
              </div>
            </Col>
            {footerData.map((section, index) => (
              <Col
                sx={6}
                md={4}
                lg={4}
                className={`${styles.linkCol}`}
                key={index}
              >
                <h4 className="mb-4">{section.title}</h4>
                <ul className="list-unstyled">
                  {section.items.map((item, idx) => (
                    <li key={idx}>
                      <Link to={item.url}>{item.label}</Link>
                    </li>
                  ))}
                </ul>
              </Col>
            ))}
            <Col sx={6} md={4} lg={4} className={`${styles.linkCol}`}>
              <h4 className="mb-4">Legal</h4>
              <ul className="list-unstyled">
                {privacyData.map((item, idx) => (
                  <li key={idx}>
                    <Link to={item.url}>{item.label}</Link>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Container>

        <div className={`text-center ${styles.copyright}`}>
          <Container>
            <div className="">
              <strong> Copyright Notice:</strong> © 2024 Smart Shipping Services
              Inc. All Rights Reserved.
            </div>
          </Container>
        </div>
      </footer>
    </>
  );
};

export default Footer;
