import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";
import { AiOutlineCar } from "react-icons/ai"; // Car icon for RORO
import { FaBoxOpen } from "react-icons/fa"; // Container icon for FCL
import countryList from "react-select-country-list";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import InputGroup from "react-bootstrap/InputGroup";
import { CiSearch } from "react-icons/ci";

const TrackingComp = () => {
  const [step, setStep] = useState(1);
  const [shipmentType, setShipmentType] = useState(null); // No default selection

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);
  const options = countryList().getData();
  const [selectedOption, setSelectedOption] = useState("person");
  const [value, setValue] = useState(null);

  const handleTypeSelect = (type) => {
    setShipmentType(type);
  };
  const handleChangeCountry = (selectedOption) => {
    setValue(selectedOption);
  };

  const [formData, setFormData] = useState({
    email: "",
    fullName: "",
    number: "",
    age: "",
    password: "",
    confirmPassword: "",
  });

  // Handle input changes
  const handleChange = (e) => {
    console.log(value, "value");
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <section className={`${styles.TrackingComp}`}>
        <Container className={`${styles.container}`}>
          <Row className={`${styles.row}`}>
            <Col sx={12} md={12} lg={6}>
              <form>
                <Form.Label>VIN/Serial Number</Form.Label>
                <InputGroup
                  className="mb-3 w-100"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    className={`${styles.tracking_search}`}
                    placeholder="Enter your VIN or Serial Number"
                    aria-label="Enter your VIN or Serial Number"
                    aria-describedby="basic-addon1"
                  />
                  <InputGroup.Text id="basic-addon1">
                    <CiSearch />
                  </InputGroup.Text>
                </InputGroup>
              </form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default TrackingComp;
