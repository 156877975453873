import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";
import { CiSearch } from "react-icons/ci";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { BiSolidShip } from "react-icons/bi";

const Schedules = () => {
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [loadingPort, setLoadingPort] = useState("");
  const [dischargePort, setDischargePort] = useState("");

  const handleLoadingPortChange = (e) => {
    setLoadingPort(e.target.value);
  };

  const handleDischargePortChange = (e) => {
    setDischargePort(e.target.value);
  };

  const handleSearchClick = () => {
    if (loadingPort && dischargePort) {
      setShowProgressBar(true);
    } else {
      setShowProgressBar(false); // Ensures the div doesn't show when options aren't selected
    }
  };

  const data = [
    {
      title: "SALLAUM LINES SWITZERLAND SA",
      subtitle: "Transit:",
      date: "21 days",
      img: "/images/logooo.jpeg",
      btn: "Request a Quote",
      link: "/",
      address: "Baltimore, US",
      location: "South Locust BalTerm",
      street: "Silver Moon 24SM11",
      finalLocation: "Lagos, NG",
      startDate: "Nov 08",
      totalDays: "21 days",
      endDate: "Nov 29",
    },
    {
      title: "SALLAUM LINES SWITZERLAND SA",
      subtitle: "Transit:",
      date: "21 days",
      img: "/images/logooo.jpeg",
      btn: "Request a Quote",
      link: "/",
      address: "Baltimore, US",
      location: "South Locust BalTerm",
      street: "Silver Moon 24SM11",
      finalLocation: "Lagos, NG",
      startDate: "Nov 08",
      totalDays: "21 days",
      endDate: "Nov 29",
    },
  ];

  return (
    <>
      <section className={`${styles.Schedules}`}>
        <Container className={`${styles.container}`}>
          <Row className={`${styles.row}`}>
            <Col sx={12} md={12} lg={6}>
              <form>
                <div className={`${styles.form}`}>
                  <div className="w-100 mb-3">
                    <Form.Label>Port of Loading</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={loadingPort}
                      onChange={handleLoadingPortChange}
                    >
                      <option value="">Select pol</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </div>
                  <div className="w-100 mb-3">
                    <Form.Label>Port of Discharging</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={dischargePort}
                      onChange={handleDischargePortChange}
                    >
                      <option value="">Select pol</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </div>
                  <div className={`${styles.icon}`}>
                    <Link onClick={handleSearchClick}>
                      <InputGroup
                        className="w-100"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label></Form.Label>
                        <InputGroup.Text id="basic-addon1">
                          <CiSearch />
                        </InputGroup.Text>
                      </InputGroup>
                    </Link>
                  </div>
                  <Link to="/" className={` ${styles.link}`}>
                    <Button
                      className={`primary ${styles.primary}`}
                      type="submit"
                      variant="none"
                    >
                      Find Voyages
                    </Button>
                  </Link>
                </div>
                {/* <div className={` ${styles.footer}`}>
                  Looking for the legacy sailing schedule?{" "}
                  <Link to="/" className={` ${styles.linkk}`}>
                    View Legacy Version →
                  </Link>
                </div> */}
              </form>

              {/* Progress bar section */}
              {showProgressBar && (
                <div className={`${styles.progressBar}`}>
                  <div>
                    <Row className={`${styles.progressRow}`}>
                      {data.map((item, index) => (
                        <div className={`${styles.progressRowTwo}`}>
                          <div className={`${styles.progressRowWrap}`}>
                            <Col
                              sx={12}
                              md={8}
                              lg={8}
                              className={`${styles.progressLeftCol}`}
                              key={index}
                            >
                              <div className={`${styles.progressLeftColWrap}`}>
                                <div className={`${styles.imgWrap}`}>
                                  <img src={item.img} alt="img" />
                                </div>
                                <div className={`${styles.data}`}>
                                  <p className={`${styles.para}`}>
                                    {item.title}
                                  </p>
                                  <div className={`${styles.dataPara}`}>
                                    {item.subtitle}
                                    <span>{item.date}</span>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col
                              sx={12}
                              md={4}
                              lg={4}
                              className={`${styles.progressRightCol}`}
                            >
                              <Link
                                to={item.link}
                                className={` ${styles.link}`}
                              >
                                <Button
                                  className={`primary ${styles.primary}`}
                                  type="submit"
                                  variant="none"
                                >
                                  {item.btn}
                                </Button>
                              </Link>
                            </Col>
                          </div>

                          {/*  */}
                          <div className={`${styles.progressBottom}`}>
                            <div className={`${styles.progressBottomWrap}`}>
                              <div className={`${styles.progressTopHeadings}`}>
                                <div className={`${styles.one}`}>
                                  <p>{item.address}</p>
                                  <div className={`${styles.address}`}>
                                    {item.location}
                                  </div>
                                </div>
                                <div className={`${styles.two}`}>
                                  <p className={`${styles.address}`}>
                                    {item.street}
                                  </p>
                                </div>
                                <div className={`${styles.three}`}>
                                  {item.finalLocation}
                                </div>
                              </div>
                              {/*  */}
                              <div className={`${styles.progressDesign}`}>
                                <div className={`${styles.line}`}></div>
                                <div
                                  className={`${styles.grayBigCircle}`}
                                ></div>

                                <div className={`${styles.ship}`}>
                                  <BiSolidShip />
                                </div>
                                <div
                                  className={`${styles.graySmallCircle}`}
                                ></div>
                                <div className={`${styles.ship}`}>
                                  <BiSolidShip />
                                </div>
                                <div
                                  className={`${styles.grayBigCircle}`}
                                ></div>
                              </div>
                              {/*  */}
                              <div className={`${styles.bootomDates}`}>
                                <div className={`${styles.one}`}>
                                  {item.startDate}
                                </div>
                                <div className={`${styles.two}`}>
                                  {item.totalDays}
                                </div>
                                <div className={`${styles.three}`}>
                                  {item.endDate}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Row>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Schedules;
