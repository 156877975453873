import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";
import { AiOutlineCar } from "react-icons/ai"; // Car icon for RORO
import { FaBoxOpen } from "react-icons/fa"; // Container icon for FCL
import countryList from "react-select-country-list";
import Form from "react-bootstrap/Form";
import Select from "react-select";

const Booking = () => {
  const [step, setStep] = useState(1);
  const [shipmentType, setShipmentType] = useState(null); // No default selection

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);
  const options = countryList().getData();
  const [selectedOption, setSelectedOption] = useState("person");
  const [value, setValue] = useState(null);

  const handleTypeSelect = (type) => {
    setShipmentType(type);
  };
  const handleChangeCountry = (selectedOption) => {
    setValue(selectedOption);
  };

  const [formData, setFormData] = useState({
    email: "",
    fullName: "",
    number: "",
    age: "",
    password: "",
    confirmPassword: "",
  });

  // Handle input changes
  const handleChange = (e) => {
    console.log(value, "value");
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <section className={`${styles.Booking}`}>
        <Container className={`${styles.container}`}>
          <Row className={`${styles.row}`}>
            <Col sx={12} md={12} lg={8}>
              Booking
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Booking;
