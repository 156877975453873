import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
// import { Link } from "gatsby";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";

const data = [
  {
    imgSrc: "/images/1-1-1-2-970x546.jpeg",
    title: "Ocean Transport",
    para: "Smart Shipping’s Ocean transport services provide a robust and reliable method for shipping goods internationally via sea routes. This service is ideal for clients looking to transport bulk items, heavy machinery, vehicles, or other large-scale shipments over long distances. Smart Shipping’s partnerships with major shipping lines and a well-coordinated network of sea routes allow for optimized transit times and flexible options for clients, whether they require full container loads (FCL) or less-than-container loads (LCL). The ocean transport service includes comprehensive tracking and real-time updates, allowing clients to monitor their shipment’s progress and ensuring peace of mind. Additionally, Smart Shipping manages documentation and regulatory compliance, easing the complexities of international shipping for its clients.",
    // listItems: [
    //   {
    //     strong: "Description: ",
    //     li: "Ideal for transporting vehicles, RoRo shipping allows cars, trucks, and heavy machinery to be driven directly onto the vessel. This method is efficient, secure, and cost-effective.",
    //   },
    //   {
    //     strong: "Specialized Regions: ",
    //     li: "West Africa & the Middle East",
    //   },
    //   {
    //     strong: "Benefits: ",
    //     li: "Quick loading and unloading, reduced risk of damage, and cost savings for large vehicles.",
    //   },
    // ],
  },
  {
    imgSrc: "/images/Air-Freight-101.png",
    title: "Air Cargo",
    para: "For time-sensitive cargo, Smart Shipping offers an air cargo service that leverages its partnerships with leading airlines to provide fast, reliable delivery worldwide. Ideal for clients needing quick, efficient transport, air cargo is especially useful for high-value, perishable, or urgent goods. Through an extensive network of flight paths, Smart Shipping offers flexible scheduling and transit times to accommodate customer needs. With door-to-door and airport-to-airport options, the company can tailor the service to fit the urgency and budgetary constraints of each shipment. Additionally, Smart Shipping handles all necessary customs and documentation processes, making the air cargo process seamless and efficient.",
    // listItems: [
    //   {
    //     strong: "Description: ",
    //     li: "Container shipping is perfect for securing valuable goods, vehicles, and commercial shipments. We offer both Full Container Load (FCL) and Less than Container Load (LCL) options.",
    //   },
    //   {
    //     strong: "Specialized Regions: ",
    //     li: "West Africa & the Middle East",
    //   },
    //   {
    //     strong: "Benefits: ",
    //     li: "Enhanced security, flexible shipping options, and the ability to transport goods of various sizes.",
    //   },
    // ],
  },
  {
    imgSrc: "/images/w0nSwGCsVAFKNx2Drv1ky4A7EsSWr3d6fdurf5GK.jpg",
    title: "Sea Shipping",
    para: "Smart Shipping’s Sea shipping service is a versatile and cost-effective option for international transport, catering to clients who need a reliable and economical way to move goods overseas. Suitable for a wide array of cargo types, from commercial goods to personal shipments, this service offers flexible container options, including both FCL and LCL, allowing clients to optimize space and reduce shipping costs. Smart Shipping’s Sea shipping service covers numerous international ports and includes tracking, allowing clients to follow their shipments throughout the journey. With a focus on safe and reliable transit, this service is designed for clients who prioritize value and security in international logistics.",
    // listItems: [
    //   {
    //     strong: "Description: ",
    //     li: "For urgent and time-sensitive shipments, air freight is the fastest option. We manage the entire process, from pickup to delivery, ensuring your goods reach their destination quickly and safely.",
    //   },
    //   {
    //     strong: "Specialized Regions: ",
    //     li: "Worldwide, with a focus on West Africa & the Middle East",
    //   },
    //   {
    //     strong: "Benefits: ",
    //     li: " Fast transit times, ideal for high-value or perishable goods, and secure handling.",
    //   },
    // ],
  },
  {
    imgSrc: "/images/TruckPark_trucks_parked_-_127500_-_264b292cc7a7fa04746e0f733ab0899e0b3030cd.jpeg",
    title: "Trucking within the United States",
    para: "To bridge the gap between auction sites and shipping ports, Smart Shipping offers specialized local trucking services for transporting vehicles. This service ensures that cars purchased from auctions within the United States are efficiently and safely delivered to the nearest port for onward transport. Smart Shipping’s trucking fleet, equipped with experienced drivers and secure loading equipment, provides reliable transportation for high-value vehicles. The company manages route optimization and scheduling to ensure timely delivery and minimize transit costs. This local trucking service is crucial for clients involved in the automotive resale or export industry, offering them a seamless process from auction purchase to port delivery.",
    // listItems: [
    //   {
    //     strong: "Description: ",
    //     li: "Our sea freight services offer a cost-effective solution for shipping large quantities of goods. We provide efficient shipping routes to major ports around the world.",
    //   },
    //   {
    //     strong: "Specialized Regions: ",
    //     li: "West Africa & the Middle East",
    //   },
    //   {
    //     strong: "Benefits: ",
    //     li: "Economical for bulk goods, reliable transit schedules, and worldwide coverage.",
    //   },
    // ],
  },
  {
    imgSrc: "/images/JHS_Financial-Assistance.jpg",
    title: "Auction Assistance",
    para: "Smart Shipping’s auction assistance service offers comprehensive support for clients interested in purchasing items—primarily vehicles—through various auctions. For clients who may be unfamiliar with the auction process, Smart Shipping provides guidance in identifying items, evaluating their condition, and navigating bidding procedures to secure the best deals. This service includes bidding support, condition assessments, and documentation handling, making it easier for clients to obtain quality items without the hassle. After the auction purchase, Smart Shipping seamlessly coordinates the transport logistics, including documentation, packaging, and shipping, ensuring a streamlined and efficient process from auction to delivery.",
    // listItems: [
    //   {
    //     strong: "Description: ",
    //     li: "Need help purchasing vehicles from auctions? We offer full auction assistance, from bidding to shipping, ensuring your purchases are securely delivered to your destination.",
    //   },
    //   {
    //     strong: "Specialized Regions: ",
    //     li: "West Africa & the Middle East",
    //   },
    //   {
    //     strong: "Benefits: ",
    //     li: "Comprehensive support, hassle-free transport, and trusted auction partnerships.",
    //   },
    // ],
  },
  {
    imgSrc: "/images/Large-modern-warehouse-with-storage-area.webp",
    title: "Warehousing",
    para: "Smart Shipping provides secure warehousing services, offering clients a safe and organized space to store their goods before distribution or onward transport. These warehouses are strategically located to facilitate easy access to shipping ports and transportation hubs. Smart Shipping’s warehouses are equipped with advanced inventory systems, enabling efficient storage management and handling for a wide variety of goods. Clients can store items for short or long periods, and value-added services like order fulfillment and inventory control are available. The warehousing service allows clients to manage their inventory effectively, reducing overhead and ensuring that goods are stored under optimal conditions until they are ready for transit.",
    // listItems: [
    //   {
    //     strong: "Description: ",
    //     li: "Need help purchasing vehicles from auctions? We offer full auction assistance, from bidding to shipping, ensuring your purchases are securely delivered to your destination.",
    //   },
    //   {
    //     strong: "Specialized Regions: ",
    //     li: "West Africa & the Middle East",
    //   },
    //   {
    //     strong: "Benefits: ",
    //     li: "Comprehensive support, hassle-free transport, and trusted auction partnerships.",
    //   },
    // ],
  },
  {
    imgSrc: "/images/cargo-packing-side-image.jpg",
    title: "Packaging and Preservation",
    para: "Smart Shipping offers specialized packaging and preservation services to ensure goods are properly protected for their journey. This service involves using highquality materials and industry-standard techniques to prevent damage from moisture, temperature fluctuations, or physical impact during transit. Fragile items, high-value goods, and perishable products benefit from this service, as Smart Shipping’s packaging team applies customized preservation methods, such as waterproofing, vacuum sealing, and anti-corrosion treatments, to secure items for long-distance shipping. Proper packaging and preservation significantly reduce the risk of loss or damage, ensuring that goods arrive at their destination in excellent condition.",
    // listItems: [
    //   {
    //     strong: "Description: ",
    //     li: "Need help purchasing vehicles from auctions? We offer full auction assistance, from bidding to shipping, ensuring your purchases are securely delivered to your destination.",
    //   },
    //   {
    //     strong: "Specialized Regions: ",
    //     li: "West Africa & the Middle East",
    //   },
    //   {
    //     strong: "Benefits: ",
    //     li: "Comprehensive support, hassle-free transport, and trusted auction partnerships.",
    //   },
    // ],
  },
  {
    imgSrc: "/images/bg-header-2-1024x683.jpg",
    title: "Cargo Consolidation",
    para: "For clients with smaller shipments that may not fill an entire container, Smart Shipping offers cargo consolidation services. This cost-effective solution combines multiple small shipments from various clients into a single container, optimizing space and reducing per-unit shipping costs. Cargo consolidation is particularly beneficial for clients who want to avoid the higher expenses associated with a full container load. Smart Shipping ensures efficient use of container space, coordinates the consolidation process, and maintains a secure chain of custody to protect each client’s goods. By sharing container space, clients benefit from significant savings without sacrificing reliability or transit efficiency.",
    // listItems: [
    //   {
    //     strong: "Description: ",
    //     li: "Need help purchasing vehicles from auctions? We offer full auction assistance, from bidding to shipping, ensuring your purchases are securely delivered to your destination.",
    //   },
    //   {
    //     strong: "Specialized Regions: ",
    //     li: "West Africa & the Middle East",
    //   },
    //   {
    //     strong: "Benefits: ",
    //     li: "Comprehensive support, hassle-free transport, and trusted auction partnerships.",
    //   },
    // ],
  },
  {
    imgSrc: "/images/pexels-freestocksorg-122164-scaled.jpg",
    title: "Container Groupage",
    para: "Smart Shipping’s container groupage service enables multiple clients to share container space, particularly when shipments do not require a full container load. Through careful planning and organization, Smart Shipping combines smaller shipments from various clients into one container, distributing the cost of the container among clients. This solution is ideal for businesses with smaller shipping volumes who want to lower costs while still accessing international logistics. Smart Shipping’s container groupage service also includes optimized load management and efficient delivery scheduling, ensuring timely arrival and safe handling of goods throughout the transportation process.",
    // listItems: [
    //   {
    //     strong: "Description: ",
    //     li: "Need help purchasing vehicles from auctions? We offer full auction assistance, from bidding to shipping, ensuring your purchases are securely delivered to your destination.",
    //   },
    //   {
    //     strong: "Specialized Regions: ",
    //     li: "West Africa & the Middle East",
    //   },
    //   {
    //     strong: "Benefits: ",
    //     li: "Comprehensive support, hassle-free transport, and trusted auction partnerships.",
    //   },
    // ],
  },
  {
    imgSrc: "/images/e1d346_7807378aa5554c88a1e581d59d372dec~mv2.png",
    title: "Customs Clearance",
    para: "Navigating customs can be complex, so Smart Shipping offers comprehensive customs clearance services to handle the documentation and regulatory requirements for international shipments. With a team experienced in customs protocols and international trade laws, Smart Shipping manages every aspect of the customs process, including tariff calculations, compliance checks, and required documentation. This service is vital in avoiding delays, penalties, and complications during transit. Smart Shipping’s customs clearance service includes consultation on import/export regulations, duties, and taxes, allowing clients to optimize their costs and streamline their shipping experience.",
    // listItems: [
    //   {
    //     strong: "Description: ",
    //     li: "Need help purchasing vehicles from auctions? We offer full auction assistance, from bidding to shipping, ensuring your purchases are securely delivered to your destination.",
    //   },
    //   {
    //     strong: "Specialized Regions: ",
    //     li: "West Africa & the Middle East",
    //   },
    //   {
    //     strong: "Benefits: ",
    //     li: "Comprehensive support, hassle-free transport, and trusted auction partnerships.",
    //   },
    // ],
  },
  {
    imgSrc: "/images/ec0528b93f044aa2939ab508d9fba82c.jpg",
    title: "Cargo Insurance Arrangement",
    para: "To protect clients from potential risks associated with shipping, Smart Shipping offers cargo insurance arrangement services, providing coverage against damage, theft, and loss. This service gives clients peace of mind, knowing that their goods are financially protected in case of unforeseen incidents during transit. Smart Shipping collaborates with reputable insurance providers to offer tailored coverage plans based on the value, type, and destination of the cargo. Clients can select from comprehensive insurance options that cover a range of scenarios, ensuring that their financial investment is safeguarded from the time goods leave the origin until they reach their destination.",
    // listItems: [
    //   {
    //     strong: "Description: ",
    //     li: "Need help purchasing vehicles from auctions? We offer full auction assistance, from bidding to shipping, ensuring your purchases are securely delivered to your destination.",
    //   },
    //   {
    //     strong: "Specialized Regions: ",
    //     li: "West Africa & the Middle East",
    //   },
    //   {
    //     strong: "Benefits: ",
    //     li: "Comprehensive support, hassle-free transport, and trusted auction partnerships.",
    //   },
    // ],
  },
];

const ServicesBanner = () => {
  return (
    <section className={styles.ServicesBanner}>
      <Container className={styles.container}>
        {data.map((service, index) => (
          <Row key={index} className={styles.row}>
            {index % 2 === 0 ? (
              <>
                <Col sx={12} md={6} lg={6}>
                  <img
                    src={service.imgSrc}
                    alt={service.title}
                    className={styles.serviceImage}
                  />
                </Col>
                <Col sx={12} md={6} lg={6} className={styles.dataCol}>
                  <div>
                    <h2>{service.title}</h2>
                    <p>{service.para}</p>
                    {/* <p>{service.description}</p>
                  <h6>{service.subtitle}</h6> */}
                    {/* <ul>
                      {service.listItems.map((item, idx) => (
                        <li key={idx}>
                          <strong>{item.strong}</strong> {item.li}
                        </li>
                      ))}
                    </ul> */}
                  </div>
                </Col>
              </>
            ) : (
              <>
                <Col sx={12} md={6} lg={6} className={styles.dataCol}>
                  <div>
                    <h2>{service.title}</h2>
                    <p>{service.para}</p>
                    {/* <p>{service.description}</p>
                  <h6>{service.subtitle}</h6> */}
                    {/* <ul>
                      {service.listItems.map((item, idx) => (
                        <li key={idx}>
                          <strong>{item.strong}</strong> {item.li}
                        </li>
                      ))}
                    </ul> */}
                  </div>
                </Col>
                <Col sx={12} md={6} lg={6}>
                  <img
                    src={service.imgSrc}
                    alt={service.title}
                    className={styles.serviceImage}
                  />
                </Col>
              </>
            )}
          </Row>
        ))}
        <Link
          to="/"
          className="d-flex justify-content-center align-items-center"
        >
          <Button
            type="submit"
            variant="none"
            className={`primary ${styles.primary}`}
          >
            Get Started
          </Button>
        </Link>
      </Container>
    </section>
  );
};

export default ServicesBanner;
