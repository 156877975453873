import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
// import { Link } from "gatsby";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";
import { FaPhone } from "react-icons/fa6";
import { MdOutlineMail } from "react-icons/md";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import countryList from "react-select-country-list";

const ContactUsBanner = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState("person");
  const [value, setValue] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
const [alertType, setAlertType] = useState(''); // 'success' or 'error'

  const options = countryList().getData();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    country: null,
    phone: "",
    message: "",
  });

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };

// Add loading state for submit button
const [isSubmitting, setIsSubmitting] = useState(false);

// Modify handleChange to update form data
const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData(prev => ({
    ...prev,
    [name]: value
  }));
};

// Handle country select change
const handleCountryChange = (selectedOption) => {
  setValue(selectedOption);
  setFormData(prev => ({
    ...prev,
    country: selectedOption
  }));
};

const handleSubmit = async (e) => {
  e.preventDefault();
  setIsSubmitting(true);
  setAlertMessage(''); // Clear any existing alerts


  try {
    const response = await fetch('https://lobster-app-scymd.ondigitalocean.app/api/contact-uses', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({data:{
        ...formData,
        type: selectedOption,
        country: formData.country?.label || ''
      }})
    });

    if (response.ok) { // status code 200-299
      setAlertType('success');
      setAlertMessage('Thank you for your message. We will get back to you soon!');      // Reset form
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        country: null,
        phone: "",
        message: "",
      });
      setIsChecked(false);
      setValue(null);
    } else {
      throw new Error('Network response was not ok');
    }
  } catch (error) {
    setAlertMessage('Sorry, there was an error sending your message. Please try again.');
    console.error('Contact form submission error:', error);
  } finally {
    setIsSubmitting(false);
  }
};

// Validation check
const isFormValid = () => {
  if (selectedOption === 'person') {
    return  formData.email && formData.phone;
  }
  return formData.companyName && formData.phone;
};
  return (
    <section className={`text-center ${styles.ContactUsBanner}`}>
      <h1>We're Here to Help!</h1>
      <p className={` ${styles.dec}`}>
        Have questions or need assistance with shipping to West Africa Central
        Africa or the Middle East? Contact our team.
      </p>
      <div className={`text-center ${styles.ContactUsBannerWarp}`}>
        <Container className={`text-start ${styles.container}`}>
          <Row className={`${styles.row}`}>
            <Col sx={12} md={5} lg={3} className={`${styles.colLeft}`}>
              <h2>Smart Shipping Service</h2>
              <p>12902 Old Chapel Road Bowie Maryland USA</p>
              <div className={`${styles.linkWarap}`}>
                <Link to="/" className={`${styles.link}`} as="div">
                  <FaPhone />
                  +1 (123) 456-7890
                </Link>
                <Link to="/" className={`${styles.link}`} as="div">
                  <MdOutlineMail />
                  info@smartshippingus.com
                </Link>
                <Link to="/" className={`${styles.link}`}>
                  <Button
                    variant="none"
                    className={`secondary ${styles.button}`}
                  >
                    Send Us a Message
                  </Button>
                </Link>
              </div>
            </Col>
            <Col sx={12} md={7} lg={9} className={`${styles.colRight}`}>
              <p>Send us a message</p>
              <form onSubmit={handleSubmit}>
                <div className="d-flex mb-3" style={{ gap: "20px" }}>
                  <Form.Group
                    className="mb-3 w-100"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      size="normal"
                      type="text"
                      name="firstName"
                      className="w-full"
                      value={formData.firstName}
                      onChange={handleChange}
                      placeholder="First Name"
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 w-100"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      size="normal"
                      type="text"
                      name="lastName"

                      value={formData.lastName}
                      onChange={handleChange}
                      placeholder="Last Name"
                    />
                  </Form.Group>
                </div>
                <Form.Group>
                  <div className="d-flex mb-3" style={{ gap: "30px" }}>
                    <Form.Check
                      type="radio"
                      label="Person"
                      name="radioOptions"
                      value="person"
                      checked={selectedOption === "person"}
                      onChange={handleRadioChange}
                      className="custom-radio"
                    />
                    <Form.Check
                      type="radio"
                      label="Organization"
                      name="radioOptions"
                      value="organization"
                      checked={selectedOption === "organization"}
                      onChange={handleRadioChange}
                      className="custom-radio"
                    />
                  </div>
                </Form.Group>
                {selectedOption === "person" && (
        <Form.Group className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            name="email"
            value={formData.email}
            onChange={handleChange}
            type="email"
            placeholder="name@example.com"
            required
          />
        </Form.Group>
      )}

      {selectedOption === "organization" && (
        <Form.Group className="mb-3">
          <Form.Label>Company Name</Form.Label>
          <Form.Control
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            type="text"
            placeholder="Enter your company name"
            required
          />
        </Form.Group>
      )}

      <Form.Group controlId="formCountrySelect" className="mb-3">
        <Form.Label>Country</Form.Label>
        <Select
          options={options}
          value={value}
          onChange={handleCountryChange}
          placeholder="Country"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          type="tel"
          placeholder="Enter Your Phone Number"
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Message</Form.Label>
        <Form.Control
          name="message"
          value={formData.message}
          onChange={handleChange}
          as="textarea"
          rows={4}
          placeholder="Enter Your Message"
        />
      </Form.Group>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="I would like to receive logistics related news and marketing
                  updates via e-mail or phone."
                    onChange={handleCheckboxChange}
                    className="custom-radio"
                  />
                </Form.Group>
              
                <Button
        className={`primary ${styles.primary}`}
        type="submit"
        variant="none"
        disabled={!isFormValid() || isSubmitting}
      >
        {isSubmitting ? 'Sending...' : 'Send'}
      </Button>
      {alertMessage && (
      <div 
        className={`alert ${alertType === 'success' ? 'alert-success' : 'alert-danger'} mb-0`}
        role="alert"
      >
        {alertMessage}
      </div>
    )}
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default ContactUsBanner;
