import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
// import { Link } from "gatsby";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";

const ServiceOverview = () => {
  // const destination = [
  //   {
  //     title: "Services Overview at Smart Shipping Services Inc.",
  //     label: "Global Expertise, Local Understanding:",
  //     dec: "We specialize in shipping to West Africa and the Middle East, with a deep understanding of the unique logistics requirements in these regions.",
  //     // img: "/images/logo.svg",
  //   },
  // ];

  return (
    <section className={`${styles.ServiceOverview}`}>
      <Container className={`${styles.container}`}>
        <Row className={`${styles.row}`}>
          <Col sx={12} md={6} lg={6} className={`${styles.colRight}`}>
            <img
              className={`${styles.img}`}
              src="/images/service-overview.jpg"
              alt="img"
            />
          </Col>
          <Col sx={12} md={6} lg={6} className={`${styles.colLeft}`}>
            {/* {destination.map((section, index) => (
              <div key={index}>
                <img className={`${styles.img}`} src={link.img} alt="img" />

                <h5 className={`${styles.colLeftP}`}> {section.label}</h5>
                <p>{section.dec}</p>
              </div>
            ))} */}
            <h2>Services Overview</h2>
            <p>
              We offer a comprehensive range of
              shipping and logistics solutions designed to meet the unique needs
              of our clients. Whether you’re shipping vehicles, goods, or
              specialized cargo, we provide reliable, cost-effective services to
              destinations worldwide, with a special focus on{" "}
              <strong>West Africa</strong> and the <strong> Middle East</strong>
              .
            </p>
            <Link className={styles.formWrap} to="/contact-us/">
              <Button
                className={`primary ${styles.primary}`}
                type="submit"
                variant="none"
              >
                Reach Us
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ServiceOverview;
