import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
// import { Link } from "gatsby";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";

const ShippingBanner = () => {
  return (
    <section className={`${styles.ShippingBanner}`}>
      <Container className={`${styles.container}`}>
        <Row className={`${styles.row}`}>
          <Col sx={6} md={4} lg={3} className={`${styles.colLeft}`}>
            <h2>ShippingBanner</h2>
            <Link to="/">Learn More</Link>
          </Col>
          <Col sx={6} md={4} lg={3} className={`${styles.colRight}`}></Col>
        </Row>
      </Container>
    </section>
  );
};

export default ShippingBanner;
