import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
// import { Link } from "gatsby";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";

const data = [
  {
    imgSrc: "/images/land.jpg",
    // title: "Ocean Transport",
    para: "Smart Shipping Services Inc. is a premier shipping line specializing in comprehensive logistics and transport solutions that bridge the United States with international markets. With extensive experience in the shipping industry, we are committed to delivering reliable, cost-effective, and seamless services tailored to the needs of our clients. Our core offerings include car exports to various global destinations, air freight solutions, local clearing and customs processing, and efficient trucking within all states across the U.S. Furthermore, we extend our services to customers looking to purchase vehicles from auctions, facilitating every step from acquisition to final delivery",
  },
  {
    imgSrc: "/images/Air-Freight-101.png",
    // title: "Ocean Transport",
    para: "We proudly serve key regions in West Africa and the Middle East, ensuring that your cargo reaches destinations such as Lagos, Lome, Cotonou, Tema, Dakar, Abidjan, Pointe Noire, Luanda, Douala, Libreville, Takoradi, Banjul, Conakry, Malabo, and Freetown. With a focus on exceptional customer service and utilizing cutting-edge technology, we aim to provide a premium, digitally enabled experience that makes global shipping accessible and efficient.",
  },
  {
    imgSrc: "/images/mission.jpeg",
    // title: "Ocean Transport",
    para: "At Smart Shipping Services Inc., we value transparency, efficiency, and reliability, supporting our clients through every phase of the shipping process—from procurement and insurance to final delivery. Our team is dedicated to creating positive customer experiences, and we take pride in fostering a collaborative and nurturing work environment that translates into excellent service for our clients.",
  },
  {
    imgSrc: "/images/containers.jpg",
    // title: "Ocean Transport",
    para: "With our expertise and commitment to continuous improvement, Smart Shipping Services Inc. stands as your trusted partner in navigating the complexities of international logistics. We look forward to serving your shipping needs and exceeding your expectations.",
  },
];

const AboutUsBanner = () => {
  return (
    <section className={`${styles.AboutUsBanner}`}>
      <Container className={`${styles.container}`}>
        <h1 className="text-center">About Us</h1>
        {data.map((service, index) => (
          <Row key={index} className={styles.row}>
            {index % 2 === 0 ? (
              <>
                <Col sx={12} md={12} lg={5} className={styles.ImageCol}>
                  <img
                    src={service.imgSrc}
                    alt={service.title}
                    className={styles.serviceImage}
                  />
                </Col>
                <Col sx={12} md={12} lg={5} className={styles.dataCol}>
                  <div>
                    {/* <h2>{service.title}</h2> */}
                    <p>{service.para}</p>
                  </div>
                </Col>
              </>
            ) : (
              <>
                <Col sx={12} md={12} lg={5} className={styles.dataCol}>
                  <div>
                    {/* <h2>{service.title}</h2> */}
                    <p>{service.para}</p>
                  </div>
                </Col>
                <Col sx={12} md={12} lg={5} className={styles.ImageCol}>
                  <img
                    src={service.imgSrc}
                    alt={service.title}
                    className={styles.serviceImage}
                  />
                </Col>
              </>
            )}
          </Row>
        ))}
        <Link
          to="/contact-us/"
          className="d-flex justify-content-center align-items-center"
        >
          <Button
            type="submit"
            variant="none"
            className={`primary ${styles.primary}`}
          >
            Contact Us
          </Button>
        </Link>
      </Container>
    </section>
  );
};

export default AboutUsBanner;
