import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
// import { Link } from "gatsby";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";

const CountryDestination = () => {
  const destination = [
    {
      title: "Destinations We Cover",
      subtitle:
        "At Smart Shipping Services Inc., we specialize in shipping to a wide range of key destinations across West Africa and Central Africa. Our deep expertise in these regions ensures your goods are delivered efficiently, securely, and on time.",
      data: [
        {
          heading: "Middle East Destinations:",
          links: [
            { label: "Dubai, UAE", img: "/images/logo.svg" },
            { label: "Jeddah, Saudi Arabia", img: "/images/logo.svg" },
            { label: "Kuwait City, Kuwait", img: "/images/logo.svg" },
            { label: "Doha, Qatar", img: "/images/logo.svg" },
            { label: "Muscat, Oman", img: "/images/logo.svg" },
            { label: "Manama, Bahrain", img: "/images/logo.svg" },
          ],
          dec: "Whether you're shipping vehicles, goods, or specialized cargo, we ensure secure and timely deliveries to these major Middle Eastern destinations. Reach out to us today for a customized shipping plan to any of these locations.",
        },
      ],
    },
  ];

  return (
    <section className={`${styles.CountryDestination}`}>
      <Container className={`${styles.HowItWorkBannercontainer}`}>
        <h1>Middle East Destinations </h1>
        <p className={`${styles.p}`}>
          At Smart Shipping Services Inc., we provide reliable shipping services
          to key destinations in the Middle East, ensuring your cargo is handled
          with the utmost care and efficiency. Our expertise in this region
          allows us to offer tailored solutions for businesses and individuals
          alike.
        </p>
      </Container>
      <div className={`${styles.container}`}>
        <Container>
          {destination.map((section, index) => (
            <Row className={`${styles.row}`} key={index}>
              <>
                {section.data.map((item, dataIndex) => (
                  <React.Fragment key={dataIndex}>
                    <>
                      <h4>{item.heading}</h4>
                      {item.links.map((link, linkIndex) => (
                        <Col
                          sx={6}
                          md={3}
                          lg={3}
                          className={`${styles.colLeft}`}
                        >
                          {/* <img
                            className={`${styles.img}`}
                            src={link.img}
                            alt="img"
                          /> */}
                          <p className={`${styles.colLeftP}`} key={linkIndex}>
                            {link.label}
                          </p>
                        </Col>
                      ))}
                    </>
                    <Col
                      sx={12}
                      md={12}
                      lg={12}
                      className={`${styles.colRight}`}
                    >
                      <p className={`${styles.colRightP}`}>{item.dec}</p>
                    </Col>
                  </React.Fragment>
                ))}
              </>
            </Row>
          ))}
        </Container>
      </div>
    </section>
  );
};

export default CountryDestination;
