import React, { useEffect, useState } from "react";
import LoginForm from "../components/register/LoginForm";

const Login = () => {
  return (
    <>
      <LoginForm />
    </>
  );
};

export default Login;
