import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import styles from "../style.module.scss";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import countryList from "react-select-country-list";

const LostPassword = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [value, setValue] = useState(null);
  const options = countryList().getData();
  const [step, setStep] = useState(1);

  const [formData, setFormData] = useState({
    email: "",
    fullName: "",
    number: "",
    age: "",
    password: "",
    confirmPassword: "",
  });

  // Handle input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Function to move to the next step
  const nextStep = () => {
    setStep(step + 1);
  };

  // Function to move to the previous step
  const previousStep = () => {
    setStep(step - 1);
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleChangeCountry = (selectedOption) => {
    setValue(selectedOption);
  };

  return (
    <section className={`${styles.Register}`}>
      <Container className={`${styles.Container}`}>
        <Row className={`${styles.row}`}>
          <Col sx={12} md={3} lg={3} className={`${styles.colLeft}`}>
            <h2>Hello..</h2>
            <p className={`${styles.linkWarap}`}>
              Already have account.
              <Link to="/login/" className={`${styles.link}`}>
                {""} Click Here {""}
              </Link>
              to Login
            </p>
          </Col>
          <Col sx={12} md={9} lg={5} className={`${styles.colRight}`}>
            Have you lost your <span>password?</span>
            <form>
              <div>
                <Form.Group className="mb-3">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control type="email" placeholder="Enter Your Email" />
                </Form.Group>
                <div
                  className="d-flex justify-content-between"
                  style={{ gap: "15px", marginTop: "2rem" }}
                >
                  <Button
                    className={`w-50 prevButton ${styles.prevButton}`}
                    variant="none"
                    type="button"
                  >
                    Previous
                  </Button>
                  <Button
                    type="submit"
                    className={`w-50 nextButton ${styles.nextButton}`}
                    variant="none"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default LostPassword;
