import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";
import { AiOutlineCar } from "react-icons/ai"; // Car icon for RORO
import { FaBoxOpen } from "react-icons/fa"; // Container icon for FCL
import countryList from "react-select-country-list";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Login from "../../register/LoginForm";

const RequestQuote = () => {
  const [step, setStep] = useState(1);
  const [shipmentType, setShipmentType] = useState(null); // No default selection

  // const nextStep = () => setStep(step + 1);
  // const prevStep = () => setStep(step - 1);
  const countryOptions = countryList().getData();
  const [selectedOption, setSelectedOption] = useState("person");
  const [value, setValue] = useState(null);
  const [showForm, setShowForm] = useState(true);
const [formData, setFormData] = useState({
  shipmentType: '',
  originType: '',
  originCountry: null,
  destinationType: '',
  destinationCountry: null,
   quantity: '',
  containerType: '',
  vgm: '',
  firstName: '',
  lastName: '',
  companyName: '',
  contactCountry: null,
  email: '',
  phoneNumber: '',
  makeModel: '',
  mobility: '',
  length: '',
  width: '',
  height: '',
});
const [errors, setErrors] = useState({});
const [alertMessage, setAlertMessage] = useState('');
const [alertType, setAlertType] = useState('');
// Add validation function
const validateStepOne = () => {
  const newErrors = {};
  
  if (!formData.shipmentType) newErrors.shipmentType = 'Please select shipment type';
  if (!formData.originType) newErrors.originType = 'Please select origin type';
  if (!formData.destinationType) newErrors.destinationType = 'Please select destination type';

  // Validate origin based on type
  if (!formData.originCountry) {
    newErrors.originCountry = formData.originType === 'address' 
      ? 'Please enter origin address' 
      : 'Please select origin country';
  }

  // Validate destination based on type
  if (!formData.destinationCountry) {
    newErrors.destinationCountry = formData.destinationType === 'address' 
      ? 'Please enter destination address' 
      : 'Please select destination country';
  }

  // Additional address validation if needed
  if (formData.originType === 'address' && formData.originCountry) {
    if (formData.originCountry.length < 5) {
      newErrors.originCountry = 'Address must be at least 5 characters long';
    }
  }

  if (formData.destinationType === 'address' && formData.destinationCountry) {
    if (formData.destinationCountry.length < 5) {
      newErrors.destinationCountry = 'Address must be at least 5 characters long';
    }
  }

  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};
// Add validation function for step 2
const validateStepTwo = () => {
  const newErrors = {};
  
  if (formData.shipmentType === "RORO") {
    // Make/Model validation
    if (!formData.makeModel.trim()) {
      newErrors.makeModel = 'Make/Model is required';
    } else if (formData.makeModel.length < 2) {
      newErrors.makeModel = 'Make/Model must be at least 2 characters';
    }

    // Mobility validation
    if (!formData.mobility) {
      newErrors.mobility = 'Please select mobility type';
    }

    // Dimensions validation
    const dimensions = ['length', 'width', 'height'];
    dimensions.forEach(dim => {
      if (!formData[dim]) {
        newErrors[dim] = `${dim.charAt(0).toUpperCase() + dim.slice(1)} is required`;
      } else {
        const value = parseInt(formData[dim]);
        if (isNaN(value) || value <= 0) {
          newErrors[dim] = `${dim.charAt(0).toUpperCase() + dim.slice(1)} must be a positive number`;
        } else if (value > 10000) { // Example maximum value
          newErrors[dim] = `${dim.charAt(0).toUpperCase() + dim.slice(1)} cannot exceed 10000 cm`;
        }
      }
    });

  } else {
    // Existing FCL validation
    if (!formData.quantity) newErrors.quantity = 'Quantity is required';
    if (!formData.containerType) newErrors.containerType = 'Container type is required';
    if (!formData.vgm) newErrors.vgm = 'VGM is required';
    
    if (formData.quantity && parseInt(formData.quantity) <= 0) {
      newErrors.quantity = 'Quantity must be greater than 0';
    }
    if (formData.vgm && parseInt(formData.vgm) <= 0) {
      newErrors.vgm = 'VGM must be greater than 0';
    }
  }


  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

// Update handlers
const handleTypeSelect = (type) => {
  setFormData(prev => ({ ...prev, shipmentType: type }));
};

const handleOriginTypeChange = (e) => {

  setFormData(prev => ({ ...prev, originType: e.target.value,originCountry: null }));
};

const handleOriginCountryChange = (selected) => {
  setFormData(prev => ({ ...prev, originCountry: selected }));
};

const handleDestinationTypeChange = (e) => {

  setFormData(prev => ({ ...prev, destinationType: e.target.value , destinationCountry: null}));
};

const handleDestinationCountryChange = (selected) => {
  setFormData(prev => ({ ...prev, destinationCountry: selected }));
};
const handleQuantityChange = (e) => {
  const value = e.target.value.replace(/[^0-9]/g, ''); // Only allow numbers
  setFormData(prev => ({ ...prev, quantity: value }));
  setErrors(prev => ({ ...prev, quantity: '' }));
};

const handleContainerTypeChange = (e) => {
  setFormData(prev => ({ ...prev, containerType: e.target.value }));
  setErrors(prev => ({ ...prev, containerType: '' }));
};

const handleVGMChange = (e) => {
  const value = e.target.value.replace(/[^0-9]/g, ''); // Only allow numbers
  setFormData(prev => ({ ...prev, vgm: value }));
  setErrors(prev => ({ ...prev, vgm: '' }));
};
const handleContactChange = (field) => (e) => {
  setFormData(prev => ({ ...prev, [field]: e.target.value }));
  setErrors(prev => ({ ...prev, [field]: '' }));
};

const handleContactCountryChange = (selected) => {
  setFormData(prev => ({ ...prev, contactCountry: selected }));
  setErrors(prev => ({ ...prev, contactCountry: '' }));
};
const handleOriginAddressChange = (e) => {
  setFormData(prev => ({ ...prev, originCountry: e.target.value }));
  setErrors(prev => ({ ...prev, originCountry: '' }));
};

const handleDestinationAddressChange = (e) => {
  setFormData(prev => ({ ...prev, destinationCountry: e.target.value }));
  setErrors(prev => ({ ...prev, destinationCountry: '' }));
};

// Update nextStep function
const validateStepThree = () => {
  const newErrors = {};

  // Required field validation
  if (!formData.firstName.trim()) newErrors.firstName = 'First name is required';
  if (!formData.lastName.trim()) newErrors.lastName = 'Last name is required';
  if (!formData.companyName.trim()) newErrors.companyName = 'Company name is required';
  if (!formData.contactCountry) newErrors.contactCountry = 'Country is required';
  if (!formData.email.trim()) newErrors.email = 'Email is required';
  if (!formData.phoneNumber.trim()) newErrors.phoneNumber = 'Phone number is required';

  // Email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (formData.email && !emailRegex.test(formData.email)) {
    newErrors.email = 'Please enter a valid email address';
  }

  // Phone validation
  const phoneRegex = /^\+?[\d\s-]{10,}$/;
  if (formData.phoneNumber && !phoneRegex.test(formData.phoneNumber)) {
    newErrors.phoneNumber = 'Please enter a valid phone number';
  }

  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

// Update the form submission function
const handleSubmit =async () => {
  if (validateStepThree()) {
    setAlertMessage(''); // Clear any existing alerts

    // Handle form submission
    try {
      // Prepare the data
      const submitData = {
        ...formData,
        originCountry: formData?.originCountry?.label || formData?.originCountry,
        destinationCountry: formData?.destinationCountry?.label || formData?.destinationCountry,
        contactCountry: formData?.contactCountry?.label
      };

      const response = await fetch('https://lobster-app-scymd.ondigitalocean.app/api/estimates', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({data:{...submitData}})
      });

      if (response.ok) {
        // Success
        setAlertType('success');
        setAlertMessage('Your quote request has been submitted successfully!');
        
        // Reset form
        setFormData({
          shipmentType: '',
          originType: '',
          originCountry: null,
          destinationType: '',
          destinationCountry: null,
          quantity: '',
          containerType: '',
          vgm: '',
          firstName: '',
          lastName: '',
          companyName: '',
          contactCountry: null,
          email: '',
          phoneNumber: '',
          makeModel: '',
          mobility: '',
          length: '',
          width: '',
          height: '',
        });
        
        // Reset step
        setStep(1);
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      setAlertType('error');
      setAlertMessage('Sorry, there was an error submitting your request. Please try again.');
      console.error('Form submission error:', error);
    }
  }
};

// Update the step navigation functions
const nextStep = () => {
  if (step === 3) {
    handleSubmit();
  } else if (step === 2 && validateStepTwo()) {
    setStep(3);
  } else if (step === 1 && validateStepOne()) {
    setStep(2);
  }
};


const prevStep = () => {
  setStep(step - 1);
};
  return (
    <>
      <section className={`${styles.RequestQuote}`}>
        <Container className={`${styles.container}`}>
          <Row className={`${styles.row}`}>
            <Col sx={12} md={12} lg={8}>
              <div className={styles.formContainer}>
                {/* Step Navigation */}
                <div className={styles.steps}>
                  <div
                    className={`${styles.step} ${
                      step === 1 ? styles.active : ""
                    }`}
                  >
                    1. Shipment Details
                  </div>
                  <div
                    className={`${styles.step} ${
                      step === 2 ? styles.active : ""
                    }`}
                  >
                    2. Cargo Details
                  </div>
                  <div
                    className={`${styles.step} ${
                      step === 3 ? styles.active : ""
                    }`}
                  >
                    3. Contact Info
                  </div>
                </div>
                {alertMessage && (
                <div 
                  className={`alert ${alertType === 'success' ? 'alert-success' : 'alert-danger'} mt-3`}
                  role="alert"
                >
                  {alertMessage}
                </div>
              )}

                {/* Step 1: Shipment Details */}
             
        {step === 1 && (
          <div className={styles.formContent}>
            <h3 className="my-5">Shipment & Route Details</h3>

            <div className={styles.typeSelection}>
              <div
                className={`${styles.tab} ${
                  formData.shipmentType === "RORO" ? styles.activeTab : ""
                }`}
                onClick={() => handleTypeSelect("RORO")}
              >
                <AiOutlineCar className={styles.icon} />
                <span>RORO</span>
                {formData.shipmentType === "RORO" && (
                  <span className={styles.checkmark}>✔</span>
                )}
              </div>

              <div
                className={`${styles.tab} ${
                  formData.shipmentType === "FCL" ? styles.activeTab : ""
                }`}
                onClick={() => handleTypeSelect("FCL")}
              >
                <FaBoxOpen className={styles.icon} />
                <span>FCL</span>
                {formData.shipmentType === "FCL" && (
                  <span className={styles.checkmark}>✔</span>
                )}
              </div>
            </div>
            {errors.shipmentType && (
              <div className="text-danger mb-3">{errors.shipmentType}</div>
            )}
 <form>
      <div className="d-flex w-100" style={{ gap: "15px" }}>
        <div className={`${styles.formGroup}`} style={{ width: "30%" }}>
          <Form.Group controlId="originType">
            <Form.Label>
              Origin Type <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              value={formData.originType}
              onChange={handleOriginTypeChange}
            >
              <option value="">Select Type</option>
              <option value="port">Port</option>
              <option value="address">Address</option>
            </Form.Select>
            {errors.originType && (
              <div className="text-danger">{errors.originType}</div>
            )}
          </Form.Group>
        </div>

        <div className={`${styles.formGroup}`} style={{ width: "70%" }}>
          <Form.Group controlId="originCountry">
            <Form.Label>
              {formData.originType === 'address' ? 'Origin Address' : 'Origin Country'} <span className="text-danger">*</span>
            </Form.Label>
            {formData.originType === 'address' ? (
              <Form.Control
                type="text"
                placeholder="Enter Address"
                value={formData.originCountry || ''}
                onChange={handleOriginAddressChange}
              />
            ) : (
              <Select
                options={countryOptions}
                value={formData.originCountry}
                onChange={handleOriginCountryChange}
                placeholder="Select Country"
              />
            )}
            {errors.originCountry && (
              <div className="text-danger">{errors.originCountry}</div>
            )}
          </Form.Group>
        </div>
      </div>

      <div className="d-flex w-100 mt-3" style={{ gap: "15px" }}>
        <div className={`${styles.formGroup}`} style={{ width: "30%" }}>
          <Form.Group controlId="destinationType">
            <Form.Label>
              Destination Type <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              value={formData.destinationType}
              onChange={handleDestinationTypeChange}
            >
              <option value="">Select Type</option>
              <option value="port">Port</option>
              <option value="address">Address</option>
            </Form.Select>
            {errors.destinationType && (
              <div className="text-danger">{errors.destinationType}</div>
            )}
          </Form.Group>
        </div>

        <div className={`${styles.formGroup}`} style={{ width: "70%" }}>
          <Form.Group controlId="destinationCountry">
            <Form.Label>
              {formData.destinationType === 'address' ? 'Destination Address' : 'Destination Country'} <span className="text-danger">*</span>
            </Form.Label>
            {formData.destinationType === 'address' ? (
              <Form.Control
                type="text"
                placeholder="Enter Address"
                value={formData.destinationCountry || ''}
                onChange={handleDestinationAddressChange}
              />
            ) : (
              <Select
                options={countryOptions}
                value={formData.destinationCountry}
                onChange={handleDestinationCountryChange}
                placeholder="Select Country"
              />
            )}
            {errors.destinationCountry && (
              <div className="text-danger">{errors.destinationCountry}</div>
            )}
          </Form.Group>
        </div>
      </div>

              <div className="d-flex justify-content-end align-items-center mt-4">
                <Button
                  className={`primary ${styles.primary}`}
                  variant="none"
                  type="button"
                  onClick={nextStep}
                >
                  Continue
                </Button>
              </div>
            </form>
          </div>
        )}

                {/* Step 2: Cargo Details */}
                {step === 2 && (
  <div className={styles.formContent}>
    <h3 className="my-5">Cargo Details</h3>
    <form>
      <div className={`${styles.formWrap}`}>
        {formData.shipmentType === "RORO" ? (
          // RORO specific fields
          <>
            <div className={`${styles.formGroup}`}>
              <Form.Group className="mb-3 w-100" controlId="makeModel">
                <Form.Label>
                  Make/Model <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  size="normal"
                  type="text"
                  className="w-full"
                  placeholder="Enter Make/Model"
                  value={formData.makeModel}
                  onChange={handleContactChange('makeModel')}
                />
                {errors.makeModel && (
                  <div className="text-danger">{errors.makeModel}</div>
                )}
              </Form.Group>
            </div>

            <div className={`${styles.formGroup}`}>
              <Form.Group controlId="mobility">
                <Form.Label>
                  Mobility <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  value={formData.mobility}
                  onChange={handleContactChange('mobility')}
                >
                  <option value="">Select Mobility Type</option>
                  <option value="regular">Regular</option>
                  <option value="tracking">Tracking</option>
                </Form.Select>
                {errors.mobility && (
                  <div className="text-danger">{errors.mobility}</div>
                )}
              </Form.Group>
            </div>

            <div className={`${styles.formGroup}`}>
              <Form.Label>
                Dimensions (cm) <span className="text-danger">*</span>
              </Form.Label>
              <div className="d-flex" style={{ gap: "15px" }}>
                <Form.Group controlId="length">
                  <Form.Control
                    size="normal"
                    type="text"
                    placeholder="Length"
                    value={formData.length}
                    onChange={handleContactChange('length')}
                  />
                  {errors.length && (
                    <div className="text-danger">{errors.length}</div>
                  )}
                </Form.Group>

                <Form.Group controlId="width">
                  <Form.Control
                    size="normal"
                    type="text"
                    placeholder="Width"
                    value={formData.width}
                    onChange={handleContactChange('width')}
                  />
                  {errors.width && (
                    <div className="text-danger">{errors.width}</div>
                  )}
                </Form.Group>

                <Form.Group controlId="height">
                  <Form.Control
                    size="normal"
                    type="text"
                    placeholder="Height"
                    value={formData.height}
                    onChange={handleContactChange('height')}
                  />
                  {errors.height && (
                    <div className="text-danger">{errors.height}</div>
                  )}
                </Form.Group>
              </div>
            </div>
          </>
        ) : (
          // Existing FCL fields
          <>
          <div className={`${styles.formGroup}`}>
            <Form.Group className="mb-3 w-100" controlId="quantity">
              <Form.Label>
                Quantity <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                size="normal"
                type="text"
                className="w-full"
                placeholder="Quantity"
                value={formData.quantity}
                onChange={handleQuantityChange}
              />
              {errors.quantity && (
                <div className="text-danger">{errors.quantity}</div>
              )}
            </Form.Group>
          </div>

          <div className={`${styles.formGroup}`} style={{ width: "100%" }}>
            <Form.Group controlId="containerType">
              <Form.Label>
                Container Type <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                value={formData.containerType}
                onChange={handleContainerTypeChange}
              >
                <option value="">Select Container Type</option>
                <option value="20ft">20ft Container</option>
                <option value="40ft">40ft Container</option>
                <option value="40ft-hc">40ft High Cube</option>
              </Form.Select>
              {errors.containerType && (
                <div className="text-danger">{errors.containerType}</div>
              )}
            </Form.Group>
          </div>

          <div className={`${styles.formGroup}`} style={{ width: "100%" }}>
            <Form.Group controlId="vgm">
              <Form.Label>
                VGM (kg) <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                size="normal"
                type="text"
                className="w-full"
                placeholder="Weight"
                value={formData.vgm}
                onChange={handleVGMChange}
              />
              {errors.vgm && (
                <div className="text-danger">{errors.vgm}</div>
              )}
            </Form.Group>
          </div>
        </>
        )}
      </div>

      <div className={styles.formActions} style={{ gap: "20px" }}>
        <Button
          type="button"
          onClick={prevStep}
          className={`primary ${styles.primary}`}
          variant="none"
        >
          Previous
        </Button>
        <Button
          type="button"
          onClick={nextStep}
          className={`primary ${styles.primary}`}
          variant="none"
        >
          Continue
        </Button>
      </div>
    </form>
  </div>
)}

                {/* Step 3: Contact Info */}
                {step === 3 && (
                  <div className={styles.formContent}>
                    {/* <h3 className="mt-5 mb-3">Request a Quote</h3>
                    <p>We are ready for your next shipment</p> */}

                    {/* Show login or custom form based on showForm state */}
                    {!showForm ? (
                      <div>
                        {/* Login form */}
                        {/* <div className={`${styles.colRight}`}>
                          <h2>Log in</h2>
                          <p>Welcome! Log in to your account below.</p>
                          <form>
                            <div>
                              <Form.Group className="mb-3">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="name@example.com"
                                />
                              </Form.Group>
                              <Form.Group
                                className="mb-3"
                                controlId="formPassword"
                              >
                                <Form.Label>Enter Your Password</Form.Label>
                                <Form.Control
                                  type="password"
                                  name="password"
                                  value={formData.password}
                                  onChange={()=>{}}
                                  required
                                />
                              </Form.Group>
                              <div className="d-flex justify-content-between my-2">
                                <Form.Check
                                  type="radio"
                                  label="Remember me"
                                  name="radioOptions"
                                  value="person"
                                  checked={selectedOption === "person"}
                                  onChange={()=>{}}
                                  className="custom-radio"
                                />
                                <Link
                                  to="/lost-password/"
                                  className={`${styles.formLink}`}
                                >
                                  Lost your password?
                                </Link>
                              </div>
                              <div
                                className="d-flex justify-content-between"
                                style={{ marginTop: "1.5rem" }}
                              >
                                <Button
                                  type="button"
                                  variant="none"
                                  className={`w-100 ${styles.logInBtn}`}
                                >
                                  Log In
                                </Button>
                              </div>
                            </div>
                          </form>
                        </div> */}
                        <p className={` ${styles.or}`}>Or</p>
                        <div className={`mt-4 ${styles.formActions}`}>
                          <Button
                            type="button"
                            onClick={() => setShowForm(true)} // On click, show the custom form
                            className={`primary ${styles.primary}`}
                            variant="none"
                          >
                            Continue without Login
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div>
                     <form onSubmit={(e) => e.preventDefault()}>
      <h5 className="my-4">Contact Details</h5>
      <div className="d-flex mb-3" style={{ gap: "15px" }}>
        <Form.Group className="w-50">
          <Form.Label>First Name <span className="text-danger">*</span></Form.Label>
          <Form.Control
            type="text"
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleContactChange('firstName')}
          />
          {errors.firstName && (
            <div className="text-danger">{errors.firstName}</div>
          )}
        </Form.Group>
        
        <Form.Group className="w-50">
          <Form.Label>Last Name <span className="text-danger">*</span></Form.Label>
          <Form.Control
            type="text"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleContactChange('lastName')}
          />
          {errors.lastName && (
            <div className="text-danger">{errors.lastName}</div>
          )}
        </Form.Group>
      </div>

      <Form.Group className="mb-3">
        <Form.Label>Company Name <span className="text-danger">*</span></Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Your Company Name"
          value={formData.companyName}
          onChange={handleContactChange('companyName')}
        />
        {errors.companyName && (
          <div className="text-danger">{errors.companyName}</div>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="formCountrySelect">
        <Form.Label>Country <span className="text-danger">*</span></Form.Label>
        <Select
          options={countryOptions}
          value={formData.contactCountry}
          onChange={handleContactCountryChange}
          placeholder="Select Country"
        />
        {errors.contactCountry && (
          <div className="text-danger">{errors.contactCountry}</div>
        )}
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Email Address <span className="text-danger">*</span></Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter Your Email"
          value={formData.email}
          onChange={handleContactChange('email')}
        />
        {errors.email && (
          <div className="text-danger">{errors.email}</div>
        )}
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Phone Number <span className="text-danger">*</span></Form.Label>
        <Form.Control
          type="text"
          placeholder="123-456-7890"
          value={formData.phoneNumber}
          onChange={handleContactChange('phoneNumber')}
        />
        {errors.phoneNumber && (
          <div className="text-danger">{errors.phoneNumber}</div>
        )}
      </Form.Group>

      <div className="d-flex justify-content-end align-items-center" style={{ gap: "20px" }}>
        <Button
          type="button"
          onClick={prevStep}
          className={`primary ${styles.primary}`}
          variant="none"
        >
          Previous
        </Button>
        <Button
          type="button"
          onClick={nextStep}
          className={`primary ${styles.primary}`}
          variant="none"
        >
          Submit
        </Button>
      </div>
    </form>
  
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default RequestQuote;
