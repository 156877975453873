import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import countryList from "react-select-country-list";

const ShippmentForm = () => {
  const [shippingFrom, setShippingFrom] = useState(null);
  const [shippingTo, setShippingTo] = useState(null);
  const options = countryList().getData();

  const handleChangeFrom = (selectedOption) => {
    setShippingFrom(selectedOption);
  };

  const handleChangeTo = (selectedOption) => {
    setShippingTo(selectedOption);
  };

  // Function to check if both countries are selected
  const areCountriesSelected = shippingFrom && shippingTo;

  return (
    <section className={` ${styles.ShippmentForm}`}>
      <Container>
        <Row
          className={`d-flex mb-3 justify-content-center align-items-center ${styles.row}`}
        >
          <Col sx={12} md={6} className={`${styles.colLeft}`}>
            <h1 className=" mb-4">Where are you Shipping?</h1>
            <form
              className="d-flex mb-3 justify-content-center align-items-center"
              style={{ gap: "30px" }}
            >
              <Col xs={6} className=" ">
                <Form.Group controlId="formCountrySelectFrom" className="mb-3">
                  <Select
                    options={options}
                    value={shippingFrom}
                    onChange={handleChangeFrom}
                    placeholder="Shipping From"
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group controlId="formCountrySelectTo" className="mb-3">
                  <Select
                    options={options}
                    value={shippingTo}
                    onChange={handleChangeTo}
                    placeholder="Shipping To"
                  />
                </Form.Group>
              </Col>
            </form>
          </Col>
        </Row>

        {/* Conditionally render icons if both countries are selected */}
        {areCountriesSelected && (
          <div
            className="d-flex mb-3 justify-content-center align-items-center"
            style={{ gap: "30px" }}
          >
            <div className="text-center">
              <div className={`${styles.imgWrap}`}>
                <Link to="/shippingForm/">
                  <img
                    src="/images/ship.png"
                    alt="Icon 1"
                    style={{ width: "60px", height: "60px" }}
                  />
                </Link>
              </div>
              <h5 className="my-3">FCL</h5>
            </div>

            <div className="text-center">
              <div className={`${styles.imgWrap}`}>
                <Link to="/shippingForm/">
                  <img
                    src="/images/car.png"
                    alt="Icon 2"
                    style={{ width: "60px", height: "60px" }}
                  />
                </Link>
              </div>
              <h5 className="my-3">RORO</h5>
            </div>
            <div className="text-center">
              <div className={`${styles.imgWrap}`}>
                <Link to="/shippingForm/">
                  <img
                    src="/images/plan.png"
                    alt="Icon 3"
                    style={{ width: "60px", height: "60px" }}
                  />
                </Link>
              </div>
              <h5 className="my-3">Air Express</h5>
            </div>
            <div className="text-center">
              <div className={`${styles.imgWrap}`}>
                <Link to="/shippingForm/">
                  <img
                    src="/images/truck.png"
                    alt="Icon 4"
                    style={{ width: "60px", height: "60px" }}
                  />
                </Link>
              </div>
              <h5 className="my-3">LCL</h5>
            </div>
          </div>
        )}
      </Container>
    </section>
  );
};

export default ShippmentForm;
