import React, { useEffect, useState } from "react";
import Register from "../components/register/RegisterForm";

const Registerindex = () => {
  return (
    <>
      <Register />
    </>
  );
};

export default Registerindex;
